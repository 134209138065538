<template>
  <div
    class="modal fade"
    id="modalAddPayment"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Pembayaran Hutang</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group">
              <label class="form-label">Kas & Bank</label>
              <Select2
                v-model="formPembayaran.id_akun"
                :options="optionKasBank"
                placeholder="Pilih akun perkiraan..."
                @change="formChangePembayaran('id_akun')"
                @select="formChangePembayaran('id_akun')"
                :class="{
                  'is-invalid':
                    formErrorPembayaran && formErrorPembayaran.id_akun,
                }"
                :settings="{
                  templateResult: formatState,
                }"
              />
              <div
                class="form-error"
                v-if="formErrorPembayaran && formErrorPembayaran.id_akun"
              >
                {{ formErrorPembayaran.id_akun }}
              </div>
            </div>
            <div class="form-group">
              <label>Tanggal Pembayaran :</label>
              <Datepicker
                :autoApply="true"
                :maxDate="new Date()"
                :class="{
                  'is-invalid':
                    formErrorPembayaran &&
                    formErrorPembayaran.tanggal_pembayaran,
                }"
                :closeOnScroll="true"
                v-model="formPembayaran.tanggal_pembayaran"
                placeholder="Tanggal Pembayaran"
                :format="format"
                :enableTimePicker="false"
                locale="id-ID"
                selectText="Pilih"
                cancelText="Batal"
                @cleared="changeDatePembayaran('', 'tanggal_pembayaran')"
                @update:modelValue="
                  changeDatePembayaran($event, 'tanggal_pembayaran')
                "
              ></Datepicker>
            </div>
            <div class="form-group">
              <label class="" for="inlineFormInputGroupUsername"
                >Nominal Pembayaran :</label
              >
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">Rp</div>
                </div>
                <input
                  type="text"
                  v-money="configMoney"
                  v-model.lazy="formPembayaran.nilai"
                  :class="{
                    'is-invalid':
                      formErrorPembayaran && formErrorPembayaran.nilai,
                  }"
                  @keyup="formChangePembayaran('nilai')"
                  class="form-control text-right"
                  placeholder="Nominal Pembayaran"
                />
              </div>
              <div
                class="form-error"
                v-if="formErrorPembayaran && formErrorPembayaran.nilai"
              >
                {{ formErrorPembayaran.nilai }}
              </div>
            </div>
            <div class="form-group">
              <label>Nomor Referensi :</label>
              <input
                type="text"
                :class="{
                  'is-invalid':
                    formErrorPembayaran && formErrorPembayaran.no_referensi,
                }"
                @keyup="formChangePembayaran('no_referensi')"
                class="form-control"
                v-model="formPembayaran.no_referensi"
                placeholder="Masukkan Nomor Referensi"
              />
              <div
                class="form-error"
                v-if="formErrorPembayaran && formErrorPembayaran.no_referensi"
              >
                {{ formErrorPembayaran.no_referensi }}
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              @click="savePembayaran"
              :disabled="!isEditable || isPembayaran"
              class="btn btn-add"
            >
              <span class="fas fa-spinner fa-spin" v-if="isPembayaran"></span>
              Simpan Pembayaran
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="modalJurnalBack"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Jurnal Kembali</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group">
              <label>Nomor Pembayaran</label>
              <Select2
                v-model="formJurnalKembali.id_pembayaran"
                :options="optionPembayaran"
                placeholder="Pilih akun perkiraan..."
                @change="formChangeJurnal('id_pembayaran', $event)"
                @select="formChangeJurnal('id_pembayaran', $event)"
                :class="{
                  'is-invalid':
                    formErrorJurnal && formErrorJurnal.id_pembayaran,
                }"
              />
              <div
                class="form-error"
                v-if="formErrorJurnal && formErrorJurnal.id_pembayaran"
              >
                {{ formErrorJurnal.id_pembayaran }}
              </div>
            </div>
            <div class="form-group">
              <label>Tanggal :</label>
              <Datepicker
                :autoApply="true"
                :class="{
                  'is-invalid': formErrorJurnal && formErrorJurnal.tanggal,
                }"
                :closeOnScroll="true"
                v-model="formJurnalKembali.tanggal"
                placeholder="Tanggal Pembayaran"
                :format="format"
                :enableTimePicker="false"
                locale="id-ID"
                selectText="Pilih"
                cancelText="Batal"
                @cleared="changeDateJurnal('', 'tanggal')"
                @update:modelValue="changeDateJurnal($event, 'tanggal')"
              ></Datepicker>
            </div>
            <div class="form-group">
              <label class="" for="inlineFormInputGroupUsername"
                >Nominal :</label
              >
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">Rp</div>
                </div>
                <input
                  type="text"
                  v-money="configMoney"
                  v-model.lazy="formJurnalKembali.nilai"
                  :class="{
                    'is-invalid': formErrorJurnal && formErrorJurnal.nilai,
                  }"
                  @keyup="formChangeJurnal('nilai')"
                  class="form-control text-right"
                  placeholder="Nominal Pembayaran"
                />
              </div>
              <div
                class="form-error"
                v-if="formErrorJurnal && formErrorJurnal.nilai"
              >
                {{ formErrorJurnal.nilai }}
              </div>
            </div>
            <div class="form-group">
              <label>Nomor Referensi :</label>
              <input
                type="text"
                :class="{
                  'is-invalid': formErrorJurnal && formErrorJurnal.no_referensi,
                }"
                @keyup="formChangeJurnal('no_referensi')"
                class="form-control"
                v-model="formJurnalKembali.no_referensi"
                placeholder="Masukkan Nomor Referensi"
              />
              <div
                class="form-error"
                v-if="formErrorJurnal && formErrorJurnal.no_referensi"
              >
                {{ formErrorJurnal.no_referensi }}
              </div>
            </div>
            <div class="form-group">
              <label>Keterangan :</label>
              <textarea
                :class="{
                  'is-invalid': formErrorJurnal && formErrorJurnal.keterangan,
                }"
                @keyup="formChangeJurnal('keterangan')"
                class="form-control"
                v-model="formJurnalKembali.keterangan"
                placeholder="Masukkan Keterangan"
              ></textarea>
              <div
                class="form-error"
                v-if="formErrorJurnal && formErrorJurnal.keterangan"
              >
                {{ formErrorJurnal.keterangan }}
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              @click="saveJurnalBack"
              :disabled="
                isJurnalback ||
                formData.status_pembayaran == 'cancel' ||
                formData.status_pembayaran == 'unpaid'
              "
              class="btn btn-add"
            >
              <span class="fas fa-spinner fa-spin" v-if="isJurnalback"></span>
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">{{ labelPage }} Payment Order</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-PO">Purchases Order </router-link>
                  </li>

                  <li class="breadcrumb-item">
                    <router-link
                      :to="{
                        name: 'DetailPO',
                        params: {
                          kode: this.formData.kode_po,
                        },
                      }"
                      >Detail Purchases Order</router-link
                    >
                  </li>

                  <li class="breadcrumb-item active" aria-current="page">
                    {{ labelPage }} Payment Order
                  </li>
                </ol>
              </nav>
            </div>
            <div
              class="button-edit"
              v-show="
                validated == 1 &&
                labelPage == 'Edit' &&
                isEditable &&
                formData.status_pembayaran == 'unpaid'
              "
            >
              <button class="btn-add" type="button" @click="editable">
                <img src="/img/icon-edit1.svg" alt="" style="width: 20px" />
                Edit
              </button>
            </div>
            <div
              v-show="
                (validated != 1 && labelPage == 'Edit') || labelPage == 'Tambah'
              "
            >
              <button class="btn btn-save" :disabled="isSubmit">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </div>

          <div class="card-table info-detail">
            <div class="row mt-2">
              <div class="col-6">
                <div class="title">{{ labelPage }} Payment Order</div>
                <div class="sub-title">
                  {{ labelPage }} informasi yang diperlukan
                </div>
              </div>
              <div
                class="col-6 d-flex justify-content-end align-items-center"
                v-if="formData.id"
              >
                <div class="form-group mb-0" v-if="formData.id">
                  <div
                    v-if="formData.status_pembayaran == 'paid'"
                    class="green-bedge px-3"
                    style="width: fit-content"
                  >
                    Paid
                  </div>
                  <div
                    v-else
                    class="red-bedge px-3 text-capitalize"
                    style="width: fit-content"
                  >
                    {{ formData.status_pembayaran }}
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-6">
                <div class="form-group" v-if="labelPage == 'Edit'">
                  <label>Nomor Payment Order :</label>
                  <input
                    disabled="true"
                    type="text"
                    class="form-control"
                    placeholder="Masukkan Nomor Payment Order"
                    v-model="formData.id"
                  />
                </div>

                <div class="form-group">
                  <label>Vendor/Supplier :</label>
                  <Select2
                    :disabled="true"
                    v-model="formData.id_vendor"
                    :options="optionVendor"
                    placeholder="Pilih Vendor"
                    @change="formChange('id_vendor')"
                    @select="formChange('id_vendor')"
                    :class="{
                      'is-invalid': formError && formError.id_vendor,
                    }"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.id_vendor"
                  >
                    {{ formError.id_vendor }}
                  </div>
                </div>

                <div class="form-group">
                  <label>Akun Hutang :</label>
                  <Select2
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    v-model="formData.beban_akun"
                    :options="optionAccountPayble"
                    :settings="{
                      templateResult: formatState,
                    }"
                    placeholder="Pilih Akun Hutang"
                    @change="formChange('beban_akun')"
                    @select="formChange('beban_akun')"
                    :class="{
                      'is-invalid': formError && formError.beban_akun,
                    }"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.beban_akun"
                  >
                    {{ formError.beban_akun }}
                  </div>
                </div>
                <div class="form-group">
                  <label>Potongan Hutang</label>
                  <input
                    disabled
                    type="text"
                    class="form-control text-right"
                    placeholder="Potongan Hutang"
                    :value="
                      formatMoney(
                        countPotongan(
                          formData.nilai,
                          formData.potongan_hutang,
                          'potongan'
                        )
                      )
                    "
                  />
                </div>
                <div class="form-group">
                  <label class="" for="inlineFormInputGroupUsername"
                    >Keterangan :</label
                  >
                  <textarea
                    type="text"
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    class="form-control"
                    v-model="formData.keterangan"
                    placeholder="Keterangan"
                  />
                </div>
              </div>

              <div class="col-6">
                <!-- <div class="form-group">
                  <label>Nomor Referensi :</label>
                  <input
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    type="text"
                    class="form-control"
                    placeholder="Masukkan Nomor Referensi"
                    v-model="formData.no"
                  />
                </div> -->
                <div class="form-group">
                  <label>Departemen :</label>
                  <Select2
                    :disabled="true"
                    v-model="formData.id_divisi"
                    :options="optionDepartement"
                    placeholder="Pilih Departemen"
                    @change="formChange('id_divisi')"
                    @select="formChange('id_divisi')"
                    :class="{
                      'is-invalid': formError && formError.id_divisi,
                    }"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.id_divisi"
                  >
                    {{ formError.id_divisi }}
                  </div>
                </div>
                <div class="form-group">
                  <label>Jatuh Tempo :</label>
                  <Datepicker
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    :autoApply="true"
                    :closeOnScroll="true"
                    v-model="formData.jatuh_tempo"
                    placeholder="Jatuh PO"
                    :format="format"
                    :enableTimePicker="false"
                    locale="id-ID"
                    selectText="Pilih"
                    cancelText="Batal"
                    :class="{
                      'is-invalid': formError && formError.jatuh_tempo,
                    }"
                    @update:modelValue="changeDate($event, 'jatuh_tempo')"
                  ></Datepicker>
                  <div
                    class="form-error"
                    v-if="formError && formError.jatuh_tempo"
                  >
                    {{ formError.jatuh_tempo }}
                  </div>
                </div>

                <div class="form-group">
                  <label>PPh :</label>
                  <div class="input-group">
                    <input
                      :disabled="validated == 1 && labelPage == 'Edit'"
                      type="text"
                      class="form-control text-right"
                      placeholder="Masukkan PPh"
                      v-model="formData.pajak"
                      @keyup="formChange('pajak')"
                      v-maska="'##############'"
                    />
                    <div class="input-group-append">
                      <div class="input-group-text">%</div>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Akun Beban Pajak :</label>
                  <Select2
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    v-model="formData.beban_pajak"
                    :options="optionAccountTax"
                    :settings="{
                      templateResult: formatState,
                    }"
                    placeholder="Pilih Akun Beban Pajak"
                    @change="formChange('beban_pajak')"
                    @select="formChange('beban_pajak')"
                    :class="{
                      'is-invalid': formError && formError.beban_pajak,
                    }"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.beban_pajak"
                  >
                    {{ formError.beban_pajak }}
                  </div>
                </div>
                <div class="form-group" v-if="labelPage != 'Tambah'">
                  <label>Sisa Pembayaran</label>
                  <input
                    type="text"
                    :value="formatMoney(sisaPembayaran)"
                    disabled
                    class="form-control text-right"
                  />
                </div>
              </div>
            </div>

            <div v-if="labelPage == 'Tambah'">
              <div class="row mt-2">
                <div class="col-12">
                  <div class="title">Rincian Payment Order</div>
                  <div class="sub-title">
                    {{ labelPage }} informasi yang diperlukan
                  </div>
                </div>
              </div>
              <hr />
              <div class="d-flex justify-content-end">
                <div class="card-total">
                  <div class="total-debit d-flex flex-column mr-0">
                    <span>Nilai</span>
                    <span class="ml-auto"
                      >Rp
                      {{
                        formatMoney(
                          countPotongan(
                            formData.nilai,
                            formData.potongan_hutang
                          )
                        )
                      }}</span
                    >
                  </div>
                </div>
              </div>

              <table
                class="table table-bordered table-striped mt-3"
                id="tablePO"
              >
                <thead>
                  <tr>
                    <th style="width: 20%">Kode Barang</th>
                    <th style="width: 30%">Nama</th>
                    <th style="width: 20%">Jumlah</th>
                    <th style="width: 20%">Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(value, index) in listDetail" :key="index">
                    <td>{{ value.kode_barang }}</td>
                    <td>{{ value.nama_barang }}</td>
                    <td>{{ value.qty + " " + value.nama_satuan }}</td>
                    <td class="text-right">
                      {{ formatMoney(value.qty * value.harga_satuan) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else>
              <div>
                <div class="d-flex justify-content-between align-items-center">
                  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link active"
                        id="pills-detail-tab"
                        data-toggle="pill"
                        href="#pills-detail"
                        role="tab"
                        aria-controls="pills-detail"
                        aria-selected="true"
                        >Rincian Hutang</a
                      >
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="pills-anggota-tab"
                        data-toggle="pill"
                        href="#pills-anggota"
                        role="tab"
                        aria-controls="pills-anggota"
                        aria-selected="false"
                        >Pembayaran</a
                      >
                    </li>
                  </ul>
                  <button
                    type="button"
                    class="btn btn-download"
                    v-show="
                      formData.status_pembayaran != 'cancel' &&
                      formData.status_pembayaran != 'unpaid'
                    "
                    @click="flipJournal"
                  >
                    Jurnal Kembali
                  </button>
                </div>

                <hr style="margin-top: 0px" />
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-detail"
                    role="tabpanel"
                    aria-labelledby="pills-detail-tab"
                  >
                    <div class="d-flex justify-content-end">
                      <div class="card-total">
                        <div class="total-debit d-flex flex-column mr-0">
                          <span>Nilai</span>
                          <span class="ml-auto"
                            >Rp
                            {{
                              formatMoney(
                                countPotongan(
                                  formData.nilai,
                                  formData.potongan_hutang
                                )
                              )
                            }}</span
                          >
                        </div>
                      </div>
                    </div>

                    <div v-if="formError && formError.nilai">
                      <div
                        class="form-error"
                        :style="{ marginBottom: '5px', fontSize: '15px' }"
                      >
                        Masukan Terlebih dahulu rincian hutang
                      </div>
                    </div>

                    <div class="table-responsive mt-4">
                      <table
                        class="table table-bordered table-striped"
                        id="table"
                      >
                        <thead>
                          <tr>
                            <th style="width: 20%">Kode Barang</th>
                            <th style="width: 30%">Nama</th>
                            <th style="width: 20%">Jumlah</th>
                            <th style="width: 20%">Subtotal</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(value, index) in listDetail" :key="index">
                            <td>{{ value.kode_barang }}</td>
                            <td>{{ value.nama_barang }}</td>
                            <td>{{ value.qty + " " + value.nama_satuan }}</td>
                            <td class="text-right">
                              {{ formatMoney(value.qty * value.harga_satuan) }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade"
                    id="pills-anggota"
                    role="tabpanel"
                    aria-labelledby="pills-anggota-tab"
                  >
                    <div class="d-flex justify-content-end">
                      <div class="card-total">
                        <button
                          class="btn btn-add"
                          type="button"
                          @click="showModalPayment"
                          v-if="
                            labelPage != 'Tambah' &&
                            formData.status_pembayaran != 'paid'
                          "
                        >
                          Pembayaran
                        </button>
                        <!-- <button class="btn btn-print-invoice" data-toggle="modal"
                                                    data-target="#modalPrintInvoice">Cetak Invoice</button> -->
                      </div>
                    </div>
                    <div class="table-responsive mt-3">
                      <table
                        class="table table-bordered table-striped table-hover"
                        id="tablePembayaranHutang"
                      >
                        <thead>
                          <tr>
                            <th style="width: 15%">Nomor Pembayaran</th>
                            <th style="width: 20%">Tanggal</th>
                            <th style="width: 20%">Jumlah</th>
                            <th style="width: 10%">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(value, index) in listPayment"
                            :key="index"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Klik untuk melihat detail"
                          >
                            <td>{{ value.no_transaksi }}</td>
                            <td>{{ formatDate(value.tanggal_pembayaran) }}</td>
                            <td class="text-right">
                              {{ formatMoney(value.nilai) }}
                            </td>
                            <td>
                              <div
                                class="green-bedge"
                                v-if="value.tipe == 'pembayaran'"
                              >
                                Paid
                              </div>
                              <div class="orange-bedge" v-else>
                                Jurnal Kembali
                              </div>
                            </td>
                          </tr>
                          <tr v-if="listPayment.length === 0">
                            <td colspan="5" class="text-center">
                              Tidak ada data
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../../components/Sidebar.vue";
import NavbarDashboard from "../../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";
import Datepicker from "vue3-date-time-picker";
import { ref } from "vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";

import { checkRules, cksClient, showAlert } from "../../../../helper";
import { get_ListDivisi, get_SatuanList } from "../../../../actions/master";
import { get_ListVendor } from "../../../../actions/vendor";
import {
  get_BarangList,
  post_PaymentOrderSave,
} from "../../../../actions/barang";
import { get_PurchaseOrderDetail } from "../../../../actions/barang/purchaseOrder";
import moment from "moment";
import "moment/locale/id";

import { maska } from "maska";
import { VMoney } from "v-money";
import {
  get_AkunDefault,
  get_AkunList,
} from "../../../../actions/akun_perkiraan";
import {
  get_HutangDetail,
  post_HutangSavePembayaran,
  post_HutangJurnalBack,
} from "../../../../actions/hutang";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    Datepicker,
    // TableLite
  },

  directives: {
    money: VMoney,
    maska,
  },

  data() {
    return {
      validated: 1,
      isEditable: true,
      optionVendor: [],
      optionDepartement: [],
      optionGoods: [],
      optionAccountPayble: [],
      optionAccountTax: [],
      optionKasBank: [],
      id_company: cksClient().get("_account").id_company,
      accessDepartement: cksClient().get("_account").akses_departement,
      optionUnit: [],
      configMoney: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
      //FORMDATA
      id: this.$route.params.id_hutang ? this.$route.params.id_hutang : "",
      labelPage: this.$route.params.id_hutang ? "Edit" : "Tambah",
      tanggal_po: "",
      isSubmit: false,
      formData: {
        id: this.$route.params.id_hutang ? this.$route.params.id_hutang : "",
        kode_po: this.$route.params.kode_po ? this.$route.params.kode_po : "",
        id_company: cksClient().get("_account").id_company,
        id_vendor: "",
        id_divisi: "",
        beban_akun: "",
        nilai: "",
        jatuh_tempo: "",
        id_jurnal: "",
        status: "aktif",
        status_pembayaran: "unpaid",
        keterangan: "",
        pajak: "",
        potongan_hutang: 0,
        beban_pajak: "",
        nilai_pajak: "",
      },
      formError: [],
      rules: {
        id_vendor: {
          required: true,
        },
        beban_akun: {
          required: true,
        },
        nilai: {
          required: true,
        },
        jatuh_tempo: {
          required: true,
        },
      },
      listDetail: [],
      listPayment: [],

      //pembayaran
      sisaPembayaran: 0,
      formPembayaran: {
        no_transaksi: "",
        id_hutang: this.$route.params.id_hutang
          ? this.$route.params.id_hutang
          : "",
        tanggal_pembayaran: "",
        no_referensi: "",
        status: "aktif",
        nilai: 0,
        id_akun: "",
      },
      formErrorPembayaran: [],
      isPembayaran: false,
      rulesPembayaran: {
        no_referensi: {
          required: false,
        },
        nilai: {
          required: true,
          min: 1,
          max: 100000,
        },
        tanggal_pembayaran: {
          required: true,
        },
        id_akun: {
          required: true,
        },
      },

      //jurnal kembali
      isJurnalback: false,
      optionPembayaran: [],
      formJurnalKembali: {
        id_hutang: this.$route.params.id ? this.$route.params.id : "",
        id_pembayaran: "",
        akun_kas: "",
        tanggal: "",
        nilai: "",
        no_referensi: "",
        keterangan: "",
      },
      rulesJurnalKembali: {
        id_pembayaran: {
          required: true,
        },
        tanggal: {
          required: true,
        },
        nilai: {
          required: true,
          min: 1,
          max: 0,
        },
        no_referensi: {
          required: false,
        },
        keterangan: {
          required: false,
        },
      },
      formErrorJurnal: [],
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  mounted() {
    // console.log(this.checkModuleAccess("inventory", "cru_approval_po"));
    // $("#tablePO").DataTable({
    //   info: false,
    // });
    // $("#tableHsitory").DataTable({
    //   info: false,
    // });
    // this.tooltip();
  },

  created() {
    if (this.labelPage == "Tambah") {
      this.getDefaultAkun();
    }
    this.getBarang();
    this.getDivisi();
    this.getVendor();
    this.getSatuan();
    this.getAkun("8");
    this.getAkun("8,9,14,15,19");
    this.getAkun("1");
    this.getData();
    if (this.formData.id) {
      this.getHutang();
    }
  },

  methods: {
    getDefaultAkun() {
      var id_divisi =
        this.accessDepartement && this.formData.id_divisi
          ? this.formData.id_divisi
          : "";
      var name_id = "akun_hutang_payment_order,akun_pajak_payment_order";
      get_AkunDefault(
        {
          id_company: this.id_company,
          name_id: name_id,
          id_divisi: id_divisi,
        },
        (res) => {
          var list = res.list;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              if (key == 0) {
                this.formData.beban_akun = element;
              } else if (key == 1) {
                this.formData.beban_pajak = element;
              }
            }
          }
        }
      );
    },
    editable() {
      this.validated = 2;
    },
    showModalPayment() {
      this.countSisaPembayaran();
      this.formPembayaran.no_transaksi = "";
      this.formPembayaran.tanggal_pembayaran = "";
      this.formPembayaran.no_referensi = "";
      this.formPembayaran.status = "";
      this.formPembayaran.nilai = "";
      this.formPembayaran.id_akun = "";
      $("#modalAddPayment").modal("show");
    },

    formatDate(string) {
      return moment(string).format("DD MMMM YYYY");
    },

    getHutang() {
      get_HutangDetail(
        this.id,
        (res) => {
          const { data, invoice, pembayaran, is_editable } = res;
          this.formData = data;
          this.umur = moment().diff(
            moment(data.created_at).format("YYYY-MM-DD"),
            "days"
          );
          this.formData.jatuh_tempo = data.jatuh_tempo
            ? moment(data.jatuh_tempo).format("YYYY-MM-DD")
            : "";
          delete this.formData.created_at;
          // this.formDetail = [];
          // for (const key in jurnal_data) {
          //   if (Object.hasOwnProperty.call(jurnal_data, key)) {
          //     var element = jurnal_data[key];
          //     delete element.no_jurnal;
          //     this.formDetail.push(element);
          //   }
          // }
          this.invoiceList = invoice;
          this.listPayment = pembayaran;
          this.optionPembayaran = [];
          for (const i in pembayaran) {
            if (Object.hasOwnProperty.call(pembayaran, i)) {
              const el = pembayaran[i];
              if (el.tipe == "pembayaran") {
                this.optionPembayaran.push({
                  id: el.no_transaksi,
                  text: el.no_transaksi,
                  id_akun: el.id_akun,
                  nilai: el.nilai_kembali
                    ? el.nilai - el.nilai_kembali
                    : el.nilai,
                });
              }
            }
          }
          this.isEditable = is_editable;
          this.countSisaPembayaran();
          this.countPajak();
        },
        () => {
          showAlert(this.$swal, {
            title: "WARNING!",
            msg: "Data hutang tidak ditemukan",
            showCancelButton: true,
            showConfirmButton: false,
            onCancel: () => {
              this.$router.push({
                name: "DataAccountPayable",
              });
            },
          });
        }
      );
    },

    countSisaPembayaran() {
      var sisa = parseInt(
        this.countPotongan(this.formData.nilai, this.formData.potongan_hutang)
      );
      for (const key in this.listPayment) {
        if (Object.hasOwnProperty.call(this.listPayment, key)) {
          const element = this.listPayment[key];
          sisa -= element.nilai;
        }
      }
      this.sisaPembayaran = sisa;
      this.rulesPembayaran.nilai.max = this.formatMoney(sisa);
    },

    getAkun(tipe = "") {
      var id_divisi =
        this.accessDepartement && this.formData.id_divisi
          ? this.formData.id_divisi
          : "";
      get_AkunList(
        {
          id_company: this.id_company,
          tipe: tipe,
          id_divisi: id_divisi,
        },
        (res) => {
          var data = [];
          const { list } = res;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              data.push({
                id: element.id,
                text: `${element.nama}`,
                no: element.id,
                type: element.tipe,
                sub_level: parseInt(element.sub_level),
                disabled:
                  parseInt(element.max_subLevel) === parseInt(element.sub_level)
                    ? false
                    : true,
              });
            }
          }
          if (tipe == "8") {
            this.optionAccountPayble = data;
          } else if (tipe == "8,9,14,15,19") {
            this.optionAccountTax = data;
          } else if (tipe == "1") {
            this.optionKasBank = data;
          }
        }
      );
    },
    getData() {
      get_PurchaseOrderDetail(this.formData.kode_po, (res) => {
        var { data, detail, potongan } = res;
        delete data.created_at;
        delete data.updated_at;
        this.formData.id_vendor = data.id_vendor;
        this.formData.id_divisi = data.id_divisi;
        this.listDetail = detail;
        this.formData.potongan_hutang = potongan;
        var nilai = 0;
        for (const key in detail) {
          if (Object.hasOwnProperty.call(detail, key)) {
            const element = detail[key];
            nilai += element.qty * element.harga_satuan;
          }
        }
        this.formData.nilai = this.formatMoney(nilai);
      });
    },

    countPotongan($nilai, $potongan, type = "") {
      var _nilai = $nilai ? parseInt($nilai.toString().split(".").join("")) : 0;
      var _potongan = $potongan ? parseInt($potongan) : 0;
      var _fixPotongan = Math.ceil((_nilai * _potongan) / 100);
      console.log(_nilai, _fixPotongan, $potongan);
      if (!type) {
        return _nilai - _fixPotongan;
      } else if (type == "potongan") {
        return _fixPotongan;
      }
    },

    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-3" : state.sub_level == 1 ? "pl-2" : "";
      var $state = $(
        '<div class="' +
          padding +
          '">' +
          state.text +
          '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
          state.no +
          "<div>" +
          state.type +
          "</div></div></div>"
      );
      return $state;
    },

    //master
    countHarga(harga_satuan, qty) {
      var harga = parseInt(harga_satuan.toString().split(".").join(""))
        ? parseInt(harga_satuan.toString().split(".").join(""))
        : 0;
      return this.formatMoney(harga * qty);
    },
    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getDivisi() {
      get_ListDivisi({ id_company: this.id_company }, (res) => {
        const { list } = res;
        this.optionDepartement = [];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            this.optionDepartement.push(element);
          }
        }
      });
    },
    getSatuan() {
      get_SatuanList(
        (res) => {
          var { list } = res;
          this.optionUnit = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionUnit.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
        },
        (e) => {
          console.log(e);
        }
      );
    },
    async getBarang() {
      await get_BarangList(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          var { list } = res;
          this.optionGoods = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionGoods.push({
                text: element.nama,
                id: element.kode,
                satuan: element.satuan,
                nama_satuan: element.nama_satuan,
                nama_kategori: element.nama_kategori,
                pagu: element.harga_pagu,
              });
            }
          }
        },
        () => {
          this.list = [];
        }
      );
    },
    getVendor() {
      get_ListVendor(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          const { list } = res;
          this.optionVendor = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionVendor.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
        }
      );
    },
    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },
    changeDate(data, key) {
      if (key == "tanggal_pembayaran") {
        this.formPembayaran[key] = moment(data).format("YYYY-MM-DD");
      } else {
        this.formData[key] = moment(data).format("YYYY-MM-DD");
        this.formChange(key);
      }
    },
    async formChange(key) {
      if (this.rules[key]) {
        this.rules[key].changed = true;
      }
      if (key == "pajak") {
        this.countPajak();
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
    },

    countPajak() {
      var data = 0;
      for (const key in this.listDetail) {
        if (Object.hasOwnProperty.call(this.listDetail, key)) {
          const element = this.listDetail[key];
          data += element.qty * element.harga_satuan;
        }
      }
      var pajak = parseInt(this.formData.pajak)
        ? parseInt(this.formData.pajak)
        : 0;
      var nilai = Math.round((data * pajak) / 100);
      this.formData.nilai = nilai + data;
      this.formData.nilai_pajak = nilai;
      this.rules.nilai.max = nilai + data;
    },

    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.id
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },

    postData() {
      var dataForm = this.formData;
      dataForm.nilai = this.formData.nilai;
      this.isSubmit = true;
      post_PaymentOrderSave(
        dataForm,
        (res) => {
          this.isSubmit = false;
          var msg =
            res.response_code === 201
              ? "Data payment order berhasil dicatat"
              : "Data payment order berhasil diperbarui";
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            onSubmit: () => {
              this.$router.push({
                name: "DetailPO",
                params: {
                  kode: this.formData.kode_po,
                },
              });
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "ERROR!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },

    //PEMBAYARAN
    postPembayaran() {
      var dataForm = this.formPembayaran;
      dataForm.nilai = this.formPembayaran.nilai.split(".").join("");
      this.isPembayaran = true;
      post_HutangSavePembayaran(
        dataForm,
        (res) => {
          this.isPembayaran = false;
          var msg =
            res.response_code === 201
              ? "Data pembayaran Payment Order berhasil dicatat"
              : "Data pembayaran Payment Order berhasil diperbarui";
          this.getHutang();

          $("#modalAddPayment").modal("hide");
          setTimeout(() => {
            this.isPembayaran = false;
            showAlert(this.$swal, {
              title: "BERHASIL!",
              msg: msg,
            });
          }, 500);
        },
        () => {
          this.isPembayaran = false;
          setTimeout(() => {
            this.isPembayaran = false;
            showAlert(this.$swal, {
              title: "ERROR!",
              msg: "Terjadi kesalahan, silakan ulangi kembali",
              showCancelButton: true,
              showConfirmButton: false,
            });
          }, 500);
        }
      );
    },

    //pembayaran
    changeDatePembayaran(data, key) {
      this.formPembayaran[key] = moment(data).format("YYYY-MM-DD");
      this.formChangePembayaran(key);
    },

    async formChangePembayaran(val) {
      if (Object.hasOwnProperty.call(this.rulesPembayaran, val)) {
        this.rulesPembayaran[val].changed = true;
      }
      var check = await checkRules(this.rulesPembayaran, this.formPembayaran);
      this.formErrorPembayaran = check.error;
    },

    async savePembayaran() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      this.formPembayaran.nilai = this.formatMoney(this.formPembayaran.nilai);
      var check = await checkRules(this.rulesPembayaran, this.formPembayaran);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postPembayaran();
          },
        });
      }
    },

    //jurnal kembali
    async formChangeJurnal(val, value = "") {
      if (Object.hasOwnProperty.call(this.rulesJurnalKembali, val)) {
        this.rulesJurnalKembali[val].changed = true;
      }

      if (val == "id_pembayaran") {
        this.rulesJurnalKembali.nilai.max = this.formatMoney(value.nilai);
        this.formJurnalKembali.akun_kas = value.id_akun;
      }

      var check = await checkRules(
        this.rulesJurnalKembali,
        this.formJurnalKembali
      );
      this.formErrorJurnal = check.error;
    },
    changeDateJurnal(data, key) {
      this.formJurnalKembali[key] = moment(data).format("YYYY-MM-DD");
      this.formChangeJurnal(key);
    },
    flipJournal() {
      this.formJurnalKembali.id_pembayaran = "";
      this.formJurnalKembali.tanggal = "";
      this.formJurnalKembali.nilai = "";
      this.formJurnalKembali.keterangan = "";
      this.formJurnalKembali.no_referensi = "";
      $("#modalJurnalBack").modal("show");
      // this.$router.push({
      //   name: "FlipJournalPayble",
      //   params: {
      //     label: "Hutang",
      //   },
      // });
    },
    postJurnalBack() {
      this.isJurnalback = true;
      post_HutangJurnalBack(
        this.formJurnalKembali,
        () => {
          $("#modalJurnalBack").modal("hide");
          this.isJurnalback = false;
          this.getHutang();
          setTimeout(() => {
            showAlert(this.$swal, {
              title: "BERHASIL!",
              msg: "Jurnal kembali berhasil dicatat",
            });
          }, 500);
        },
        () => {
          this.isJurnalback = false;
          showAlert(this.$swal, {
            title: "ERROR!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    async saveJurnalBack() {
      for (const key in this.rulesJurnalKembali) {
        if (Object.hasOwnProperty.call(this.rulesJurnalKembali, key)) {
          this.rulesJurnalKembali[key].changed = true;
        }
      }
      var check = await checkRules(
        this.rulesJurnalKembali,
        this.formJurnalKembali
      );
      this.formErrorJurnal = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postJurnalBack();
          },
        });
      }
    },
  },
};
</script>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>

<style scoped>
.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}
</style>
